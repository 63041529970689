<template>
  <div
    v-loading="isLoading"
    class="structure">
    <div v-if="STRUCTURE_REQUEST_STATUS === 'none'">
      <el-button
        type="primary"
        class="structure__btn"
        @click="fetchStructure">
        Загрузить структуру
      </el-button>
    </div>
    <ul
      v-else
      class="structure__list">
      <li
        v-for="(item, i) in Object.entries(STRUCTURE)"
        :key="i">
        <list-item :item-data="item" />
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ListItem from './listItem.vue';

export default {
  name: 'Structure',
  components: {
    ListItem,
  },
  computed: {
    ...mapGetters(['STRUCTURE', 'STRUCTURE_REQUEST_STATUS']),
    isLoading() {
      return this.STRUCTURE_REQUEST_STATUS === 'requested';
    },
  },
  methods: {
    ...mapActions(['getStructure']),
    fetchStructure() {
      const tireModelId = this.$route.params.tire_model_id;
      this.getStructure(tireModelId);
    },
  },
};
</script>

<style lang="scss">
.structure {
  min-height: 70vh;
  ul, li {
    text-align: left;
  }
  ul {
    font-family: 'Roboto Mono', monospace;
    font-weight: 300;
    border-left: 1px dashed #dddddd;
    padding-left: 1em;
  }
  li {
    font-size: 13px;
    list-style-type: none;
    color: #606266;
    font-weight: 400;
    &:last-child {
      border-bottom: none;
    }
    span {
      height: 25px;
      display: flex;
      align-items: center;
    }
    b {
      text-transform: capitalize;
      margin-right: 0.5em;
    }
    .el-collapse-item__header {
      text-transform: capitalize;
      font-weight: bold;
      height: auto;
    }
  }
  &__list {
    border: 1px solid #EBEEF5;
    padding: 20px;
    margin: 0;
  }
  &__btn {
    margin-top: 33vh;
  }
}
</style>
