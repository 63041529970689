<template>
  <span v-if="isPrimitive">
    <b>{{ key }}:</b>
    {{ value }}
  </span>
  <div v-else>
    <div>
      <span><b>{{ key }}:</b></span>
      <ul>
        <li
          v-for="item in Object.entries(value)"
          :key="item[0]">
          <ItemList :item-data="item" />
        </li>
      </ul>
    </div>
  </div>
</template>
<script>

import isObject from 'lodash.isobject';

export default {
  name: 'ItemList',
  props: {
    itemData: {
      type: [Number, String, Array, Object, Boolean],
      default: '',
    },
  },
  computed: {
    isPrimitive() {
      return !isObject(this.value);
    },
    key() {
      const [key] = this.itemData;
      return key;
    },
    value() {
      const [, value] = this.itemData;
      return value || 'Null';
    },
  },
};
</script>
